import { FormProps } from '@sourcelabbg/form/lib'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import { SofiaMonthInput } from './custom-types'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './date.module.css'
import { useEffect, useState } from 'react'
import { t } from 'i18next'

export default function DateCard({ field, formProps }: { field: SofiaMonthInput; formProps: FormProps }) {
  const [minEndDate, setMinEndDate] = useState<any>(null)
  const [maxStartDate, setMaxStartDate] = useState<any>(null)
  return (
    <div>
      <Controller
        control={formProps.control}
        name={field.name}
        render={({ field: { onChange: onLocalChange, onBlur, ref, value } }) => {
          const val = formProps.getValues(field.name) || value
          useEffect(() => {
            if (val?.from === undefined) setMinEndDate(null)
            else setMinEndDate(new Date(val.from))

            if (val?.to === undefined) setMaxStartDate(null)
            else setMaxStartDate(new Date(val.to))
          }, [val])

          return (
            <div className={`p-4 border-[1px] min-h-220px ${val?.from || val?.to ? 'border-primary' : 'border-light-grey'} rounded-lg`}>
              <h3 className="font-bold mb-4">{field.uiOptions?.label && t(`${field.uiOptions?.label}`)}</h3>
              <label htmlFor={`${field.name}From`}>{t('selection.from')}</label>
              <div className="mb-4">
                <DatePicker
                  autoComplete="off"
                  dateFormat="MM/yyyy"
                  isClearable
                  selected={val?.from ? new Date(val.from) : null}
                  wrapperClassName={styles.datePicker}
                  ref={ref}
                  id={`${field.name}From`}
                  name={`${field.name}From`}
                  onChange={(d: Date) => {
                    onLocalChange({ ...val, from: d?.toString() })
                    setMinEndDate(d ? d.setMonth(d.getMonth()) : null)
                  }}
                  maxDate={maxStartDate ? maxStartDate : new Date('2100-12-31T00:00:00.000Z')}
                  minDate={new Date('1950-01-01T00:00:00.000Z')}
                  onBlur={onBlur}
                  placeholderText={'MM/YYYY'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showFourColumnMonthYearPicker
                />
              </div>
              <div>
                <label htmlFor={`${field.name}To`}>{t('selection.to')}</label>
                <DatePicker
                  autoComplete="off"
                  dateFormat="MM/yyyy"
                  isClearable
                  id={`${field.name}To`}
                  selected={val?.to ? new Date(val.to) : null}
                  wrapperClassName={styles.datePicker}
                  ref={ref}
                  name={`${field.name}To`}
                  onChange={(d: Date) => {
                    onLocalChange({ ...val, to: d ? new Date(d.getFullYear(), d.getMonth() + 1, 0) : undefined })
                    setMaxStartDate(d ? d.setMonth(d.getMonth()) : null)
                  }}
                  maxDate={new Date('2100-12-31T00:00:00.000Z')}
                  minDate={minEndDate ? minEndDate : new Date('1950-01-01T00:00:00.000Z')}
                  onBlur={onBlur}
                  placeholderText={'MM/YYYY'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showFourColumnMonthYearPicker
                />
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}
