import React, { ReactElement } from 'react'
import useSourcelabForm, { FieldChange, Values, FormProps, renderFormElement, isLayout } from '@sourcelabbg/form/lib'
import { isNotSofiaSearchField, SofiaSearchType } from './custom-inputs/custom-types'
import RadioCard from './custom-inputs/radio-card'
import SofiaSelect from './custom-inputs/sofia-select'
import DateCard from './custom-inputs/date'
import Filters from './custom-inputs/filters'
import AdvancedSearch from './custom-inputs/advanced-search'

function customRender(field: SofiaSearchType, formProps: FormProps) {
  if (isNotSofiaSearchField(field) || isLayout(field)) return field

  if (field.uiOptions?.visible && !field.uiOptions?.visible(formProps.formValues)) return <></>

  const compose = (component: ReactElement) => {
    return (
      <React.Fragment key={field.name}>
        {field.uiOptions?.label && field.uiOptions?.labelPosition === 'left' && (
          <label htmlFor={field.name} className={field.uiOptions.labelClassName}>
            {field.uiOptions.label}
          </label>
        )}
        {component}
        {field.uiOptions?.label && field.uiOptions?.labelPosition === 'right' && (
          <label htmlFor={field.name} className={field.uiOptions.labelClassName}>
            {field.uiOptions.label}
          </label>
        )}
        {formProps.error && <>Error: {formProps.error}</>}
      </React.Fragment>
    )
  }

  switch (field.type) {
    case 'custom':
      // @ts-ignore
      if (field.customControlType === 'sofia-radio-input') return compose(<RadioCard formProps={formProps} field={field} />)
      // @ts-ignore
      if (field.customControlType === 'sofia-advanced-search') return compose(<AdvancedSearch formProps={formProps} field={field} />)
      // @ts-ignore
      if (field.customControlType === 'sofia-select') return compose(<SofiaSelect formProps={formProps} field={field} />)
      // @ts-ignore
      if (field.customControlType === 'sofia-month-input') return compose(<DateCard formProps={formProps} field={field} />)
      // @ts-ignore
      if (field.customControlType === 'sofia-filters') return compose(<Filters formProps={formProps} field={field} />)
      break
    default:
      return compose(renderFormElement(field, formProps))
  }
}

export default function SofiaForm<D extends Record<string, unknown>>({
  fields,
  values,
  onSubmit,
  onFieldChange,
  onInvalidForm,
  resolver,
}: {
  fields: SofiaSearchType[]
  values: Values<D>
  onSubmit?: (data: D, event?: React.BaseSyntheticEvent) => void
  onInvalidForm?: (errors: any) => void
  onFieldChange?: FieldChange<D>
  resolver?: any // typescript won the battle but the war is not over ⚔️
}) {
  const { render, onFormSubmit } = useSourcelabForm({
    // @ts-ignore
    values,
    onSubmit,
    onFieldChange,
    // @ts-ignore
    customRender,
    onInvalidForm,
    mode: 'onBlur',
    resetAfterSubmit: true,
    nestedClassName: 'wrapper',
    resolver,
  })

  return (
    <form id="sofia-form" onSubmit={onFormSubmit}>
      {render(fields)}
    </form>
  )
}
