import addIcon from '../../../assets/orange_add.svg'
import binIcon from '../../../assets/bin.svg'
import Select, { OnChangeValue } from 'react-select'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { SofiaAdvancedSearch } from './custom-types'
import { FormProps } from '@sourcelabbg/form/lib'
import dateStyles from './date.module.css'
import styles from './input.module.css'
import { showSelection } from '../../../state/show-selection-state'
import { useRecoilValue } from 'recoil'
import { t } from 'i18next'

interface DefaultField {
  fieldName: string | undefined
  operator: 'includes' | 'excludes' | 'before' | 'after' | 'is' | 'is_not' | undefined
  value: string[]
}

const dateFieldNames = ['graduationFrom', 'graduationTo', 'graduationDate', 'dateOfBirth']
const dropDownFields = ['province', 'education', 'study', 'city', 'herkomstCode', 'template']

export const defaultField: DefaultField = {
  fieldName: undefined,
  operator: undefined,
  value: [],
}

const themeOptions = {
  primary50: 'var(--light-grey)', //color when you press and hold an option right before selecting it
  primary25: 'rgba(var(--primary-rgb), 0.3)', //color of hovered element in the dropdown
  primary: 'var(--primary)', //color of input border AND the already selected elements in the dropdown
}

export default function AdvancedSearch({ field, formProps }: { field: SofiaAdvancedSearch; formProps: FormProps }) {
  const showSelectionState = useRecoilValue<boolean>(showSelection)

  return (
    <Controller
      control={formProps.control}
      name={`${field.name}`}
      render={({ field: { onChange, value: fieldValue } }) => {
        const [currentValue, setCurrentValue] = useState([{ ...defaultField, id: Math.random() }])
        useEffect(() => {
          if (fieldValue) setCurrentValue(fieldValue)
        }, [fieldValue])

        const { data: fieldNames } = useQuery<string[]>(['advanced-filters'], async () => (await axios.get('/api/advanced-filters')).data)
        const { t } = useTranslation()
        if (!fieldNames) return <></>

        const includeExcludeOptions = [
          { label: t('fields.includes'), value: 'includes' },
          { label: t('fields.excludes'), value: 'excludes' },
        ]
        const beforeAfterOptions = [
          { label: t('fields.before'), value: 'before' },
          { label: t('fields.after'), value: 'after' },
        ]
        const isIsNotOptions = [
          { label: t('fields.is'), value: 'is' },
          { label: t('fields.is_not'), value: 'is_not' },
        ]

        const filters = (fieldName: string) => {
          if (dateFieldNames.includes(fieldName)) return beforeAfterOptions
          if (['validEmail', 'validPhone', 'dateOfBirth', 'graduationDate', 'firstName', 'lastName', 'email'].includes(fieldName))
            return isIsNotOptions

          return includeExcludeOptions
        }

        const fields = fieldNames.map((field) => ({ value: field, label: t(`advancedFilters.${field}`) }))

        return (
          <div className="px-8">
            <hr className="my-6"></hr>
            <div className="flex flex-col gap-4">
              {currentValue.map((item: any, index: number) => (
                <div id={item.id} key={item.id} className="flex flex-row justify-between w-full">
                  <div className="flex flex-row gap-4 min-h-24 px-5 py-5 w-[97%] rounded-md bg-advanced-option">
                    <div className="flex flex-col w-1/2 gap-2">
                      <h3 className="font-bold">{t('fields.filter')}</h3>
                      <div className="flex flex-row gap-4">
                        <Select
                          isSearchable={false}
                          placeholder={t('fields.choose_filter')}
                          defaultValue={fields.find((field) => field.value === item.fieldName)}
                          options={fields}
                          onChange={(event) => {
                            onChange(
                              currentValue.map((value, i) =>
                                index === i
                                  ? {
                                      ...value,
                                      fieldName: event?.value,
                                      value: [...dropDownFields, ...dateFieldNames].includes(event?.value ?? '')
                                        ? []
                                        : [...dropDownFields, ...dateFieldNames].includes(item?.fieldName ?? '')
                                        ? ''
                                        : item.value,
                                      operator: event?.value ? filters(event?.value)[0].value : undefined,
                                    }
                                  : value,
                              ),
                            )
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              ...themeOptions,
                            },
                          })}
                          styles={{
                            singleValue: (baseStyles) => ({
                              ...baseStyles,
                              borderRadius: '2px',
                              width: 'fit-content',
                              color: 'var(--primary)',
                              fontSize: '13.6px',
                              fontWeight: 'bold',
                              backgroundColor: 'var(--advanced-tags)',
                              padding: '0 10px',
                              height: '24px',
                            }),
                            container: (baseStyles) => ({ ...baseStyles, width: '65%' }),
                            control: (baseStyles) => ({ ...baseStyles, border: item.value?.length && !item.fieldName ? '1px solid red' : undefined }),
                          }}
                        />
                        <Select
                          options={filters(item.fieldName)}
                          key={`${item.fieldName}Operator`}
                          defaultValue={(item.fieldName && filters(item.fieldName).find((operator) => operator.value === item.operator)) || null}
                          placeholder={false}
                          onChange={(event) => onChange(currentValue.map((value, i) => (index === i ? { ...value, operator: event?.value } : value)))}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              ...themeOptions,
                            },
                          })}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '35%' }),
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                            }),
                            singleValue: (baseStyles) => ({
                              ...baseStyles,
                              height: '24px',
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2 gap-2">
                      <h3 className="font-bold">{t('fields.value')}</h3>
                      {(dropDownFields.includes(item.fieldName) && (
                          <DropDownValues
                            onChange={(event) =>
                              onChange(currentValue.map((value, i) => (index === i ? { ...value, value: event.map((item) => item.value) } : value)))
                            }
                            item={item}
                          />
                        )) ||
                        (dateFieldNames.includes(item.fieldName) && (
                          <>
                            <DatePicker
                              autoComplete="off"
                              dateFormat={item.fieldName === 'dateOfBirth' ? 'yyyy-MM-dd' : 'MM/yyyy'}
                              isClearable
                              selected={!Array.isArray(item.value) ? new Date(item.value) : null}
                              wrapperClassName={`${dateStyles.datePicker} ${item.fieldName && !item.value?.length ? 'invalid' : ''}`}
                              onChange={(d: Date) =>
                                onChange(currentValue.map((value, i) => (index === i ? { ...value, value: d ? d.toString() : [] } : value)))
                              }
                              maxDate={new Date('2100-12-31T00:00:00.000Z')}
                              minDate={new Date('1900-01-01T00:00:00.000Z')}
                              placeholderText={item.fieldName === 'dateOfBirth' ? 'yyyy-MM-dd' : 'MM/YYYY'}
                              showMonthYearPicker={item.fieldName === 'dateOfBirth' ? false : true}
                              showFullMonthYearPicker
                              showFourColumnMonthYearPicker
                            />
                          </>
                        )) || (
                          <input
                            type="text"
                            defaultValue={item.value?.length ? item.value : ''}
                            onChange={(event) => {
                              onChange(currentValue.map((value, i) => (index === i ? { ...value, value: event.target.value } : value)))
                            }}
                            className={`${styles.inputField} ${item.fieldName && !item.value?.length ? 'invalid' : ''}`}
                          />
                        )}
                    </div>
                  </div>
                  <img
                    src={binIcon}
                    onClick={() => {
                      const blankItem = { ...defaultField, id: Math.random() }
                      const newValue = currentValue.length === 1 ? [blankItem] : currentValue.filter((value) => value?.id != item.id)
                      setCurrentValue(newValue)
                      onChange(newValue)
                    }}
                    alt=""
                    className="pl-3 cursor-pointer max-w-8"
                  />
                </div>
              ))}
            </div>
            <div className="relative my-6 flex flex-row gap-4 items-center justify-center">
              <button
                type="button"
                onClick={() => {
                  const newValue = [...currentValue, { ...defaultField, id: Math.random() }]
                  setCurrentValue(newValue)
                  onChange(newValue)
                }}
                className="flex flex-row justify-evenly items-center bg-white border-[1px] p-2 min-w-36	h-11 border-table-outline rounded-md"
              >
                <img src={addIcon} alt="" />
                <p>{t('fields.add_new_logic')}</p>
              </button>
              <button
                type="button"
                onClick={field.onShowSelection}
                className={`${
                  showSelectionState
                    ? 'border-primary text-primary bg-white cursor-pointer'
                    : 'border-dark-grey text-dark-grey bg-light-grey cursor-default'
                } flex flex-row justify-evenly items-center border-[1px] p-2 min-w-36	h-11  rounded-md`}
                disabled={showSelectionState ? false : true}
              >
                {' '}
                <p>{t('selection.show_selection')}</p>
              </button>
              <hr className="absolute bottom-[50%] w-full -z-1"></hr>
            </div>
          </div>
        )
      }}
    />
  )
}

const DropDownValues = ({ onChange, item }: { onChange: (e: OnChangeValue<{ value: string; label: string }, true>) => void; item: any }) => {
  const { fieldName, value } = item
  const [selectedValue, setSelectedValue] = useState<string[]>(value)
  const { data: values } = useQuery<{ id: number; name: string }[]>(['advanced-filters', fieldName], async () => {
    if (!fieldName) return []
    return (await axios.get(`/api/advanced-filters/${fieldName}`)).data
  })
  useEffect(() => {
    fieldName && setSelectedValue(item.value)
  }, [fieldName, item.value])

  const optionValues = values?.map((value) => ({ value: value.id.toString(), label: value.name, data: value }))
  return (
    <Select
      isMulti
      placeholder={t('fields.choose_value')}
      options={optionValues ? optionValues : [{ value: 'loading', label: 'Loading...', data: { id: 0, name: 'loading' } }]}
      value={optionValues ? optionValues.filter((option) => selectedValue.includes(option.value)) : null}
      isOptionDisabled={(option) => option.value === 'loading'}
      hideSelectedOptions={false}
      onChange={(e: any) => {
        setSelectedValue(e.map((e: { value: any }) => e.value))
        onChange(e)
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...themeOptions,
        },
      })}
      styles={{
        multiValueLabel: (baseStyles) => ({
          ...baseStyles,
          fontWeight: 'bold',
          color: 'var(--primary)',
          padding: '0px',
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          margin: 'auto',
          color: 'var(--primary-light)',
          borderRadius: '100%',
          padding: '0px',
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          display: 'flex',
          gap: '5px',
          backgroundColor: 'var(--advanced-tags)',
          padding: '0 4px',
          height: '24px',
        }),
        container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
        control: (baseStyles) => ({ ...baseStyles, border: item.fieldName && !item.value?.length ? '1px solid red' : undefined }),
      }}
    />
  )
}
