import axios, { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { useMutation, useQuery } from 'react-query'
import { Navigate } from 'react-router-dom'
import Select from 'react-select'
import { useRecoilState } from 'recoil'
import i18n from '../i18n'
import { authenticatedUser } from '../state/user-state'
import { AuthenticatedUser, UserTypeEnum } from '../types/authenticated-user'

const userTypes = [
  { label: 'Admin', value: 'admin' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Operator', value: 'operator' },
]

const locales = [
  { label: 'English', value: 'en' },
  { label: 'Dutch', value: 'nl' },
]

const isSelectValue = (value: any): value is { label: string; value: string } => {
  return value.value !== undefined
}

type updateRequest = { azureId: string; userType?: UserTypeEnum; locale?: string }

export default function Admin() {
  const [user, setAuthenticatedUser] = useRecoilState<AuthenticatedUser>(authenticatedUser)
  const mutation = useMutation({
    mutationFn: (values: updateRequest) => {
      const { azureId, ...rest } = values
      return axios
        .post<updateRequest, AxiosResponse<AuthenticatedUser>>(`/api/update-user/${azureId}`, rest, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((response) => {
          const updatedUser = response.data

          if (azureId === user.azureId) {
            setAuthenticatedUser(updatedUser)
            i18n.changeLanguage(updatedUser.locale)
            if (updatedUser.userType !== 'admin') return <Navigate to={`/`} replace />
          }
        })
    },
  })
  const { data: users, isLoading: usersLoading } = useQuery(['users'], async () => (await axios.get('/api/users')).data)

  if (usersLoading) return <div>{t('selection.loading')}</div>

  if (user.userType !== 'admin') return <Navigate to={`/`} replace />
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Language
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((person: any) => (
                    <tr key={person.azureId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">{person.name}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-3">{person.email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Select
                          options={userTypes}
                          onChange={(e) => isSelectValue(e) && mutation.mutate({ azureId: person.azureId, userType: e.value as UserTypeEnum })}
                          defaultValue={userTypes.find((item) => item.value === person.userType) || 'disabled'}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          styles={{ container: (baseStyles) => ({ ...baseStyles, width: '200px' }) }}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Select
                          options={locales}
                          onChange={(e) => isSelectValue(e) && mutation.mutate({ azureId: person.azureId, locale: e.value })}
                          defaultValue={locales.find((item) => item.value === person.locale) || 'nl'}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          styles={{ container: (baseStyles) => ({ ...baseStyles, width: '200px' }) }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
