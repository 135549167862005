import { FormProps } from '@sourcelabbg/form/lib'
import { t } from 'i18next'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import { SofiaSelect } from './custom-types'
import excludeIcon from '../../../assets/exclude_icon.svg'
import includeIcon from '../../../assets/include_icon.svg'
import { components, GroupBase, MultiValueProps, OptionProps } from 'react-select'
import { nonEmpty, uniqueItemsInArray } from '../../../helpers/utils'

const { MultiValue, Option } = components

const highlightInputMatch = ({
  item,
  keyword,
  containerStyles,
  highlightStyles,
  fromIndex,
  title,
}: {
  item: string | undefined
  keyword: string
  containerStyles?: CSSProperties
  highlightStyles: CSSProperties
  fromIndex?: number
  title?: string
}) => {
  if (!item || !keyword) return item
  const lowerCasedInputValue = keyword.toLowerCase()
  const hitIndex = item.toLocaleLowerCase().indexOf(lowerCasedInputValue, fromIndex)
  if (hitIndex === -1) return <span title={title}>{item}</span>
  return (
    <span title={title} style={containerStyles}>
      {item.slice(0, hitIndex)}
      <span style={highlightStyles}>{item.slice(hitIndex, hitIndex + keyword.length)}</span>
      {item.slice(hitIndex + keyword.length)}
    </span>
  )
}

const MultiValueWithHighlightedInputValue = (props: MultiValueProps<SofiaOption, boolean, GroupBase<SofiaOption>>) => (
  <MultiValue {...props}>
    {highlightInputMatch({
      item: props.data.name,
      keyword: props.selectProps.inputValue,
      containerStyles: {
        backgroundColor: 'pink',
      },
      highlightStyles: {
        color: 'blue',
        fontWeight: 'bold',
      },
      title: props.data.name,
    })}
  </MultiValue>
)

const OptionWithHighlightedInputValue = (props: OptionProps<SofiaOption, boolean, GroupBase<SofiaOption>>) => (
  <Option {...props}>
    {highlightInputMatch({
      item: props.data.name,
      keyword: props.selectProps.inputValue,
      highlightStyles: {
        color: 'blue',
        fontWeight: 'bold',
      },
      fromIndex: props.data.name.indexOf(quotes),
    })}
  </Option>
)

export type SofiaOption = {
  id: string
  name: string
  data: Record<string, string | number>
  styles?: CSSProperties
  disabled?: boolean
}

const pleaseTypeId = 'please-type'
const selectAllId = 'select-all'
const deselectAllId = 'deselect-all'
const narrowDownId = 'narrow-down'
const quotes = '``'
const limitStep = 200

export default function SofiaSelectComponent({ field, formProps }: { field: SofiaSelect; formProps: FormProps }) {
  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: controlledField }) => {
        const { isMulti = true, options: allOptions = [] } = field
        const formValue = formProps.getValues(field.name)
        const fieldValue = formValue || controlledField.value

        const [inputValue, setInputValue] = useState('')
        const [limit, setLimit] = useState(limitStep)

        const selected = useMemo(
          () => allOptions.filter((option) => [...(fieldValue || [])].includes(option.id)) || [],
          [allOptions, fieldValue, field.value],
        )

        const filterOption = useCallback((candidate: { label: string; value: string; data: any }, input: string) => {
          if (!input) return true
          if ([pleaseTypeId, selectAllId, deselectAllId, narrowDownId].includes(candidate.value)) return true
          return candidate.label.toLowerCase().includes(input.toLowerCase())
        }, [])
        const filterOptions = useCallback(
          (options: SofiaOption[], inputValue: string) =>
            options
              // .sort((a, b) => a.name.localeCompare(b.name)) // TODO: Should we sort?
              .filter((o) => filterOption({ label: o.name, value: o.id, data: o.data }, inputValue)),
          [],
        )

        const getOptions = useCallback(
          (inputValue: string) => {
            const filteredOptions = filterOptions(allOptions, inputValue)
            const limitedOptions = filteredOptions.slice(0, limit)

            if (!isMulti) return limitedOptions

            const text = inputValue ? t('selection.options_containing', { searchString: inputValue, quotes }) : t('selection.options_available')

            const alreadySelectedContainingInputValueCount = selected.filter((o) => o.name.toLowerCase().includes(inputValue.toLowerCase())).length
            const deselectAllOption: SofiaOption = {
              id: deselectAllId,
              name: `${t('selection.deselect_all')} (${alreadySelectedContainingInputValueCount} ${text})`,
              data: {},
              styles: { backgroundColor: '#fd9890', color: 'black' },
            }

            const narrowDownOption: SofiaOption = {
              id: narrowDownId,
              name: `${t('selection.narrow_down')} (${alreadySelectedContainingInputValueCount} ${text})`,
              data: {},
              styles: { backgroundColor: '#fde890', color: 'black' },
            }

            const pleaseTypeOption: SofiaOption = {
              id: pleaseTypeId,
              name: `${t('selection.please_type')} (${filteredOptions.length} ${text})`,
              data: {},
              styles: { backgroundColor: 'grey', color: 'white' },
              disabled: true,
            }

            const selectedIds = selected.map((o) => o.id)
            const areAllFilteredSelected = filteredOptions.every((o) => selectedIds.includes(o.id))

            const selectAllOption: SofiaOption = {
              id: selectAllId,
              name: `${t('selection.select_all')} (${filteredOptions.length - alreadySelectedContainingInputValueCount} ${text})`,
              data: {},
              styles: { backgroundColor: '#dbfd90', color: 'black' },
            }

            const showDeselect = alreadySelectedContainingInputValueCount > 0 && !!inputValue.trim()
            const showNarrowDown = showDeselect && alreadySelectedContainingInputValueCount < selected.length
            const showPleaseType = filteredOptions.length > limitStep
            const showSelectAll = filteredOptions.length <= limitStep && filteredOptions.length > 1 && !areAllFilteredSelected

            return [
              showDeselect ? deselectAllOption : undefined,
              showNarrowDown ? narrowDownOption : undefined,
              showPleaseType ? pleaseTypeOption : undefined,
              showSelectAll ? selectAllOption : undefined,
              ...limitedOptions,
            ].filter(nonEmpty)
          },
          [allOptions, selected, limit, inputValue],
        )

        const options = useMemo(() => getOptions(inputValue), [inputValue, selected, limit])

        const loadOptions = (inputValue: string, callback: (localOptions: SofiaOption[]) => void) => {
          callback(getOptions(inputValue))
        }

        const inclusion = ['includes', undefined].includes(formProps.getValues(`${field.name}Operator`))

        return (
          <div className={`p-4 border-[1px] min-h-220px ${selected.length > 0 ? 'border-primary' : 'border-light-grey'} rounded-lg`}>
            <div className="sofia-search-select-wrapper">
              <div className="flex flex-row gap-2 mb-2 justify-between">
                <div className="flex flex-row gap-2">
                  <h3 className="font-bold">{`${field.uiOptions?.label && t(field.uiOptions?.label)}`}</h3>
                  <p className="text-primary font-bold">{selected.length}</p>
                </div>
                {field.inclusionIcon !== false && (
                  <>
                    <button
                      className="flex flex-row gap-2"
                      name={field.name}
                      type="button"
                      onClick={() => {
                        formProps.setValue(`${field.name}Operator`, !inclusion ? 'includes' : 'excludes')
                      }}
                    >
                      <img className="w-3.5 h-3.5 m-auto" src={inclusion ? includeIcon : excludeIcon} alt="" />
                      {!inclusion && <p className="m-auto text-sm">{t('fields.excludedFilter')}</p>}
                    </button>
                    <input type="hidden" {...formProps.register(`${field.name}Operator`, { value: 'includes' })} />
                  </>
                )}
              </div>

              <AsyncSelect
                className="sofia-search-select"
                classNamePrefix="sofia-search"
                isMulti={isMulti}
                isClearable={true}
                isSearchable={true}
                autoFocus={false}
                closeMenuOnSelect={!isMulti}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                defaultOptions={options}
                options={options}
                loadOptions={loadOptions}
                onMenuScrollToBottom={() => {
                  setLimit(Math.min(limit + limitStep, filterOptions(allOptions, inputValue).length + 2))
                }}
                onInputChange={(value, _actionMeta) => {
                  setLimit(limitStep)
                  setInputValue(value)
                }}
                hideSelectedOptions={false}
                value={selected}
                onChange={(val) => {
                  const keepInputValue = inputValue

                  if (Array.isArray(val)) {
                    if (val.some((o) => o.id === selectAllId)) {
                      // Select All
                      const filteredOptions = filterOptions(allOptions, inputValue)
                      controlledField.onChange(uniqueItemsInArray([...selected, ...filteredOptions]).map((o) => o.id))
                    } else if (val.some((o) => o.id === deselectAllId)) {
                      // Deselect All
                      const selectedIds = selected.map((o) => o.id)
                      const filteredOptionsIds = filterOptions(allOptions, inputValue).map((o) => o.id)
                      controlledField.onChange(selectedIds.filter((id) => !filteredOptionsIds.includes(id)))
                    } else if (val.some((o) => o.id === narrowDownId)) {
                      // Narrow Down
                      const selectedIds = selected.map((o) => o.id)
                      const filteredOptionsIds = filterOptions(allOptions, inputValue).map((o) => o.id)
                      controlledField.onChange(selectedIds.filter((id) => filteredOptionsIds.includes(id)))
                    } else {
                      controlledField.onChange(val.map((o: SofiaOption) => o.id))
                    }
                  } else {
                    controlledField.onChange([(val as SofiaOption)?.id])
                  }

                  setInputValue(keepInputValue)
                }}
                inputValue={inputValue}
                placeholder={t('selection.search_placeholder')}
                onFocus={() => setLimit(limitStep)}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                  Option: OptionWithHighlightedInputValue,
                  MultiValue: MultiValueWithHighlightedInputValue,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: 'var(--light-grey)', //color when you press and hold an option right before selecting it
                    primary25: 'rgba(var(primary-rgb), 0.3)', //color of hovered element in the dropdown
                    primary: 'var(--primary)', //color of input border AND the already selected elements in the dropdown
                  },
                })}
                styles={{
                  multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    fontWeight: 'bold',
                    color: 'var(--dark-grey)',
                  }),
                  multiValueRemove: (baseStyles) => ({
                    ...baseStyles,
                    height: '70%',
                    margin: 'auto',
                    color: 'var(--dark-grey)',
                    borderRadius: '100%',
                  }),
                  multiValue: (baseStyles) => ({
                    ...baseStyles,
                    height: '30px',
                    padding: '0 4px',
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: '5px',
                  }),
                  clearIndicator: (baseStyles) => ({
                    ...baseStyles,
                    alignSelf: 'start',
                    color: 'var(--fluorescent-red)',
                  }),
                  option: (baseStyles, { data: { styles } }) => ({ ...baseStyles, ...styles }),
                }}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
