import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DataTablePaginationProps {
  selectActiveStep: (step: number) => void
  pageCount: number
  pageNumber: number
}

export function Pagination({ selectActiveStep, pageCount, pageNumber }: DataTablePaginationProps) {
  const { t } = useTranslation()
  const [active, setActive] = useState(pageNumber)

  const prev = () => {
    if (active === 1) return

    setActive(active - 1)
    selectActiveStep(active - 1)
  }
  const next = () => {
    if (active === pageCount) return

    setActive(active + 1)
    selectActiveStep(active + 1)
  }

  return (
    <div className="flex items-center justify-between bg-white py-3">
      <p className="text-gray-400">
        {t('subscriptions.total_pages')} {pageCount}
      </p>
      <div>
        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button
            onClick={() => {
              setActive(1)
              selectActiveStep(1)
            }}
            type="button"
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <ChevronDoubleLeftIcon className="w-5 h-5" />
          </button>
          <button
            onClick={prev}
            type="button"
            className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              setActive(active)
              selectActiveStep(active)
            }}
            disabled={active > pageCount}
            aria-current="page"
            className="relative z-10 inline-flex items-center bg-primary px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {active}
          </button>
          {pageCount >= 2 && (
            <button
              onClick={() => {
                setActive(active + 1)
                selectActiveStep(active + 1)
              }}
              disabled={active + 1 > pageCount}
              type="button"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              {active + 1}
            </button>
          )}
          {pageCount >= 3 && (
            <button
              onClick={() => {
                setActive(active + 2)
                selectActiveStep(active + 2)
              }}
              disabled={active + 2 > pageCount}
              type="button"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              {active + 2}
            </button>
          )}
          {pageCount >= 4 && (
            <button
              onClick={() => {
                setActive(active + 3)
                selectActiveStep(active + 3)
              }}
              disabled={active + 3 > pageCount}
              type="button"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              {active + 3}
            </button>
          )}
          {pageCount >= 5 && (
            <button
              onClick={() => {
                setActive(active + 4)
                selectActiveStep(active + 4)
              }}
              disabled={active + 4 > pageCount}
              type="button"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              {active + 4}
            </button>
          )}
          {active < pageCount - 5 && (
            <>
              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                ...
              </span>
            </>
          )}
          <button
            onClick={next}
            type="button"
            className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <ChevronRightIcon className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              setActive(pageCount)
              selectActiveStep(pageCount)
            }}
            type="button"
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <ChevronDoubleRightIcon className="w-5 h-5" />
          </button>
        </nav>
      </div>
    </div>
  )
}
