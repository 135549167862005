import { useQuery } from 'react-query'
import { Unsubscribers, ZerobounceEmails } from '../../../server/src/db/generated'
import axios from 'axios'
import Slider from './slider'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'

type UserSubscriptionsHistoryProps = {
  email: string
  onStateChange: (value: boolean) => void
}

export default function UserSubscriptionsHistory({ email, onStateChange }: UserSubscriptionsHistoryProps) {
  const { t } = useTranslation()

  const { data: userSubscriptions, isLoading: userSubscriptionsLoading } = useQuery<Unsubscribers[]>(
    ['subscriptions/user', email],
    async () => (await axios.get(`/api/subscriptions/user/${email}`)).data,
  )

  const { data: zeroBounce, isLoading: zeroBounceLoading } = useQuery<ZerobounceEmails>(
    ['subscriptions/zerobounce', email],
    async () => (await axios.get(`/api/zerobounce/${email}`)).data,
  )

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [callback])

    return ref
  }

  const ref = useOutsideClick(() => {
    onStateChange(false)
  })

  const subscribed = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#10B981" className="w-8 h-8">
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  )

  const unsubscribed = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#CC2229" className="w-8 h-8">
      <path
        fillRule="evenodd"
        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
        clipRule="evenodd"
      />
    </svg>
  )

  if (userSubscriptionsLoading || !userSubscriptions || zeroBounceLoading) return <div>Loading</div>

  const Subscriptions = () => (
    <div ref={ref} className="px-6">
      <div className="flex flex-row justify-between py-6">
        <div className="flex flex-row items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path
              d="M11.25 11.25L11.291 11.23C11.4192 11.1659 11.5631 11.14 11.7057 11.1552C11.8482 11.1703 11.9834 11.2261 12.0952 11.3157C12.2071 11.4054 12.2909 11.5252 12.3368 11.661C12.3826 11.7968 12.3886 11.9429 12.354 12.082L11.646 14.918C11.6111 15.0572 11.6169 15.2034 11.6627 15.3394C11.7084 15.4754 11.7922 15.5954 11.9041 15.6852C12.016 15.775 12.1513 15.8308 12.294 15.846C12.4367 15.8612 12.5807 15.8352 12.709 15.771L12.75 15.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 8.25H12.008V8.258H12V8.25Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-lg font-medium pl-2 text-[#111827]">{t('subscriptions.history')}</p>
        </div>
        <button onClick={() => onStateChange(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="flow-root py-5">
        <ul className="overflow-scroll">
          {userSubscriptions
            .filter((unsubscriber: Unsubscribers) => unsubscriber.eventType === 'sign-up' || 'unsubscribe')
            .map((unsubscriber: Unsubscribers, i: number) => {
              const company = unsubscriber.emailList === 'jobnet' ? 'Jobnet' : 'Carrièrebeurs'
              const isSubscribed = unsubscriber.eventType === 'sign-up' ? true : false
              const date =
                unsubscriber.eventTimestamp &&
                new Date(unsubscriber.eventTimestamp.toString()).toLocaleString('default', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
              return (
                <li key={i}>
                  <div className="relative pb-8">
                    {i !== userSubscriptions.length - 1 ? (
                      <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>{isSubscribed ? subscribed : unsubscribed}</div>
                      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5 text-sm text-gray-500">
                        <p>
                          {isSubscribed ? t('subscriptions.subscribed_for') : t('subscriptions.unsubscribed_from')} {company}
                        </p>
                        <p>{date}</p>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
        </ul>
        <div className="flex flex-row items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path
              d="M11.25 11.25L11.291 11.23C11.4192 11.1659 11.5631 11.14 11.7057 11.1552C11.8482 11.1703 11.9834 11.2261 12.0952 11.3157C12.2071 11.4054 12.2909 11.5252 12.3368 11.661C12.3826 11.7968 12.3886 11.9429 12.354 12.082L11.646 14.918C11.6111 15.0572 11.6169 15.2034 11.6627 15.3394C11.7084 15.4754 11.7922 15.5954 11.9041 15.6852C12.016 15.775 12.1513 15.8308 12.294 15.846C12.4367 15.8612 12.5807 15.8352 12.709 15.771L12.75 15.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 8.25H12.008V8.258H12V8.25Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-lg font-medium pl-2 text-[#111827]">Zerobounce details</p>
        </div>
        {zeroBounce && (
          <>
            <ul>
              {zeroBounce.additionalInfo &&
                Object.keys(zeroBounce?.additionalInfo).map((key) => {
                  return (
                    <li key={key} className="justify-between space-x-4 pt-1.5 text-sm text-gray-500 pl-7">
                      {/* @ts-ignore */}
                      <strong>{key}:</strong> {zeroBounce.additionalInfo?.[key]}
                    </li>
                  )
                })}
            </ul>
          </>
        )}
      </div>
    </div>
  )
  return <Slider content={<Subscriptions />} />
}
