import { useQuery } from 'react-query'
import axios from 'axios'
import { CronExecutionDetails } from '../../../server/src/db/generated'
import { useTranslation } from 'react-i18next'

type CellProps = {
  data: string
}

export default function CronExecutionDetailsPage() {
  const { t } = useTranslation()

  const { data: cronExecutionDetails, isLoading: isLoadingCronExecutionDetails } = useQuery<CronExecutionDetails[]>(
    ['cron-execution-details'],
    async () => (await axios.get('/api/cron-execution-details')).data,
  )

  const columns = [t('cron.date'), 'Levarne', 'Data Entry', 'GGF', 'Emark Unsubscribers', 'Unsubscribers']

  const Cell = ({ data }: CellProps) => <td className="text-dark-grey whitespace-nowrap py-4 pl-4  text-base sm:pl-6 lg:pl-8">{data}</td>

  if (isLoadingCronExecutionDetails) return <div>No data</div>

  return (
    <div className="w-full my-12">
      <h1 className="mx-10 text-4xl font-bold">{t('cron.title')}</h1>
      <div className="px-8 my-7 overflow-scroll overflow-scroll">
        <table className="bordered min-w-full rounded-full divide-y divide-table-outline">
          <thead className="bg-light-grey">
            <tr>
              {columns?.map((column) => (
                <th key={column} scope="col" className="py-3.5 text-left text-base font-normal text-dark-grey sm:pl-6 lg:pl-8">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-table-outline bg-white">
            {cronExecutionDetails?.map((details, i: number) => {
              const date = new Date(details.date.toString()).toLocaleString('default', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <tr key={i} className="hover:bg-light-grey">
                  <Cell data={date} />
                  <Cell data={`${details.levarneCount}`} />
                  <Cell data={`${details.dataEntryCount}`} />
                  <Cell data={`${details.ggfCount}`} />
                  <Cell data={`${details.emarkUnsubscribersCount}`} />
                  <Cell data={`${details.unsubscribersCount}`} />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
