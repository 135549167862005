import { useTranslation } from 'react-i18next'
import styles from './filters.module.css'
import { useState } from 'react'
import { Fields } from '../../../state/columns-state'

export type FilterValue = { key: Fields; value?: boolean }

export const columnToggles: FilterValue[] = [
  { key: 'lastName', value: true },
  { key: 'nobiliary', value: true },
  { key: 'firstName', value: true },
  // { key: 'aboneenummer', value: false },
  { key: 'gender', value: true },
  { key: 'birthDate', value: true },
  { key: 'address', value: true },
  { key: 'phoneMobile', value: true },
  { key: 'city', value: true },
  { key: 'postcode', value: true },
  // Land
  { key: 'graduationDate', value: true },
  { key: 'email', value: true },
  { key: 'educationName', value: true },
  { key: 'universityName', value: true },
  { key: 'studyName', value: true },
  { key: 'educationLevelName', value: true },
  { key: 'herkomstCodeName', value: true },
  { key: 'phoneWork', value: false },
  { key: 'entryDate', value: false },
  { key: 'provinceName', value: false },
  { key: 'specialisationName', value: false },
]

export const defaultColumns = columnToggles.filter((column) => !!column.value).map((column) => column.key)

export default function Filters({ value, onChange }: { value: Fields[]; onChange: (newValue: Fields[]) => void }) {
  const { t } = useTranslation()

  const [selectedFields, setSelectedFields] = useState<FilterValue[]>(
    columnToggles.map((column) => ({ ...column, value: value.includes(column.key) ? true : false })),
  )

  return (
    <div className="flex flex-row flex-wrap gap-1">
      {selectedFields.map((filter) => (
        <div className="inline-flex items-center rounded bg-light-grey mx-0.5 px-1 py-1 text-sm font-medium text-dark-grey gap-1" key={filter.key}>
          <input
            className={`${styles.sofiaCheck} bg-gray-200 checked:bg-primary relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out`}
            type="checkbox"
            role="switch"
            onChange={(e) => {
              const newFields = selectedFields.map((field) => ({ ...field, value: field.key === filter.key ? e.target.checked : field.value }))
              setSelectedFields(newFields)
              onChange(newFields.filter((field) => field.value).map((field) => field.key))
            }}
            defaultChecked={filter.value}
            defaultValue={filter.key}
            id={filter.key}
          />

          <label className="font-bold" htmlFor={filter.key}>
            {t(`fields.${filter.key}`)}
          </label>
        </div>
      ))}
    </div>
  )
}
