import { SearchSchema } from '@/schema'
import { ChangeEvent, useCallback, useState } from 'react'
import close from '../assets/close.svg'
import { t } from 'i18next'
import { FormSchema } from '../client-schema'
import { TemplateType } from '../../../server/src/types'
import Slider from './slider'

export type UpdateRequest = {
  userId?: string
  name: string
  description: string
  isPublic: boolean
  template?: SearchSchema
}

export default function TemplateEditor({
  onStateChange,
  filters,
  onTemplateCreate,
  action,
  fields,
}: {
  onStateChange: (value: boolean) => void
  filters?: FormSchema | SearchSchema
  onTemplateCreate: (data: Pick<TemplateType, 'name' | 'description' | 'template'> & { isPublic: boolean }) => void
  action?: 'edit'
  fields?: { name?: string; description?: string; isPublic?: boolean }
}) {
  const Template = useCallback(() => {
    const [name, setName] = useState(fields?.name || '')
    const [description, setDescription] = useState(fields?.description || '')
    const [publicity, setPublicity] = useState(fields?.isPublic || false)
    return (
      <>
        <div>
          <div className="w-full px-6 py-8 bg-dark-grey text-white">
            <div className="flex flex-row justify-between">
              <h2 className="text-lg font-bold">{!action ? t('templates.newTemplate') : t('templates.editTemplate')}</h2>
              <img src={close} className="cursor-pointer" onClick={() => onStateChange(false)} alt="" />
            </div>
            <p>{!action ? t('templates.bigNewText') : t('templates.bigEditText')}</p>
          </div>
          <div className="flex flex-col gap-6 w-full p-6">
            <span className="flex flex-col gap-1">
              <p>{t('templates.templateName')}</p>
              <input
                defaultValue={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setName(e.target.value)
                }}
                type="text"
                className="templateName w-full h-8 rounded-md border-light-grey"
              />
            </span>
            <span className="flex flex-col gap-1">
              <p>{t('templates.templateDescription')}</p>
              <textarea
                defaultValue={description}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
                className="w-full px-3 py-2 min-h-20 h-30 rounded-md border-light-grey"
              />
            </span>
            <span className="flex flex-col gap-1">
              <p>{t('templates.privacy')}</p>
              <div className="flex flex-row gap-3 mb-4">
                <input
                  defaultChecked={!fields?.isPublic}
                  id="private"
                  name="isPublic"
                  type="radio"
                  onClick={() => setPublicity(false)}
                  className="h-4 w-4 mt-1 border-radio-outline text-primary focus:ring-transparent"
                />
                <span className="flex flex-col gap-1">
                  <label htmlFor="private" className="font-bold">
                    {t('templates.private_title')}
                  </label>
                  <label htmlFor="private">{t('templates.private_explanation')}</label>
                </span>
              </div>
              <div className="flex flex-row gap-3">
                <input
                  defaultChecked={fields?.isPublic}
                  id="public"
                  name="isPublic"
                  type="radio"
                  onClick={() => setPublicity(true)}
                  className="h-4 w-4 mt-1 border-radio-outline text-primary focus:ring-transparent"
                />
                <span className="flex flex-col gap-1">
                  <label htmlFor="public" className="font-bold">
                    {t('templates.public_title')}
                  </label>
                  <label htmlFor="public">{t('templates.public_explanation')}</label>
                </span>
              </div>
            </span>
          </div>
        </div>
        <div>
          <hr className="mt-auto w-full"></hr>
          <div className="flex flex-row justify-end p-4 gap-2">
            <button onClick={() => onStateChange(false)} className="w-24 border-1 border-dark-grey rounded-md py-2">
              {t('templates.cancel')}
            </button>
            <button
              onClick={() => {
                if (filters && onTemplateCreate)
                  onTemplateCreate({
                    name,
                    description,
                    isPublic: publicity,
                    template: filters,
                  })
                onStateChange(false)
              }}
              className="w-24 bg-primary rounded-md py-2 text-white"
            >
              {t('templates.save')}
            </button>
          </div>
        </div>
      </>
    )
  }, [])

  return <Slider content={<Template />} />
}
