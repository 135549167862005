import * as z from 'zod'
import { fieldsEnum, ruleSchema } from '@/schema'

export const formSchema = z.object({
  graduation: z
    .object({
      from: z.string().optional(),
      to: z.string().optional(),
    })
    .optional(),
  entryDate: z
    .object({
      from: z.string().optional(),
      to: z.string().optional(),
    })
    .optional(),
  email: z.string().optional(),
  educationIds: z.array(z.string()).optional(),
  educationLevelIds: z.array(z.string()).optional(),
  studiesIds: z.array(z.string()).optional(),
  schoolsIds: z.array(z.string()).optional(),
  herkomstCodesIds: z.array(z.string()).optional(),
  graduationYears: z.array(z.string()).optional(),
  limit: z.number().int().positive().optional(),
  validEmail: z.string().optional(),
  filterEmail: z.string(),
  validPhone: z.string().optional(),
  fields: z.array(fieldsEnum),
  advancedSearch: z.array(ruleSchema).optional(),
  educationIdsOperator: z.enum(['includes', 'excludes']).optional(),
  educationLevelIdsOperator: z.enum(['includes', 'excludes']).optional(),
  herkomstCodesIdsOperator: z.enum(['includes', 'excludes']).optional(),
  studiesIdsOperator: z.enum(['includes', 'excludes']).optional(),
  templateOperator: z.enum(['includes', 'excludes']).optional(),
})

export type FormSchema = z.infer<typeof formSchema>
