import {BaseSyntheticEvent, useCallback, useMemo, useState} from 'react'
import Result from '../components/result'
import SearchForm from '../components/search-form'
import TemplateEditor, { UpdateRequest } from '../components/template-editor'
import { SearchSchema } from '../../../server/src/search-schema'
import { useMutation, useQuery } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import { useRecoilState } from 'recoil'
import { AuthenticatedUser } from '../types/authenticated-user'
import { authenticatedUser } from '../state/user-state'
import { Fields, columnsState } from '../state/columns-state'
import { TemplateType } from '../../../server/src/types'
import { useNavigate, useParams } from 'react-router-dom'
import { FormSchema } from '../client-schema'
import { t } from 'i18next'
import { convertSearchSchemaToFormSchema } from '../helpers'
import { defaultColumns } from '../components/forms/custom-inputs/filters'
import {PartialFormSchemaWithFields} from "./index";

export default function Index() {
  const [user] = useRecoilState<AuthenticatedUser>(authenticatedUser)
  const [columns] = useRecoilState<Fields[]>(columnsState)
  const [searchCriteria, setSearchCriteria] = useState<SearchSchema>({} as SearchSchema)
  const [showTemplateEditor, setTemplateEditor] = useState(false)
  const [formValues, setValues] = useState<FormSchema>()
  const { templateId } = useParams()
  const navigate = useNavigate()

  const { data: template, isLoading: templateLoading } = useQuery(
    ['templates', templateId],
    async () => (await axios.get(`/api/get-template/${templateId}`)).data,
    {
      onSuccess: (data) => {
        if (!data) return
        setValues(convertSearchSchemaToFormSchema(data.template))
        onSubmit(data.template)
      },
      cacheTime: 0,
    },
  )

  const createTemplateMutation = useMutation({
    mutationFn: (values: UpdateRequest) => {
      return axios
        .post('/api/templates', values, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(() => navigate('/templates'))
    },
  })

  const updateMutation = useMutation({
    mutationFn: (values: { id: number; template: SearchSchema }) => {
      const { id, ...rest } = values
      return axios
        .post<UpdateRequest, AxiosResponse<AuthenticatedUser>>(`/api/update-template/${id}`, rest, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(() => navigate('/templates'))
    },
  })

  const onSubmit = useCallback((searchData: SearchSchema, _partialFormDataWithFields?: PartialFormSchemaWithFields, event?: BaseSyntheticEvent) => {
    event?.preventDefault()
    event?.stopPropagation()
    const submitter = ((event?.nativeEvent as SubmitEvent) || undefined)?.submitter
    const submitterName = submitter?.getAttribute('name')
    const submitterValue = submitter?.getAttribute('value')
    setSearchCriteria(searchData)
    if (submitterName === 'action') {
      setTemplateEditor(true)
    }
  }, [])

  const onTemplateCreate = (data: Pick<TemplateType, 'name' | 'description' | 'template'> & { isPublic: boolean }) => {
    if (data) {
      createTemplateMutation.mutate({
        userId: user.azureId,
        name: data.name,
        description: data.description,
        template: data.template,
        isPublic: data.isPublic,
      })
    }
  }

  const onTemplateSave = (searchCriteria: SearchSchema) => {
    updateMutation.mutate({
      id: Number(templateId),
      template: searchCriteria,
    })
  }

  const onSaveAsNewTemplate = useCallback((searchCriteria: SearchSchema) => {
    setSearchCriteria(searchCriteria)
    setTemplateEditor(true)
  }, [])

  const newFormValues = useMemo(() => formValues ? { ...formValues, fields: columns } : { fields: defaultColumns }, [formValues, columns])

  const onResetFilter = (filterKey: keyof FormSchema) => {
    if (!formValues) return
    const newValuesToSet = { ...formValues, [filterKey]: Array.isArray(formValues[filterKey]) ? [] : null }
    setValues(newValuesToSet)
  }

  if (templateId && !formValues && templateLoading) return <>{t('selection.loading')}</>

  return (
    <div className="w-full my-12">
      <SearchForm
        onSubmit={onSubmit}
        onTemplateSave={onTemplateSave}
        onSaveAsNewTemplate={onSaveAsNewTemplate}
        templateName={template.name}
        formValues={newFormValues}
        onResetFilter={onResetFilter}
      />

      {Object.keys(searchCriteria).length > 0 && <Result searchCriteria={searchCriteria} />}
      {showTemplateEditor && (
        <TemplateEditor
          filters={searchCriteria}
          onTemplateCreate={onTemplateCreate}
          onStateChange={(event: boolean) => setTemplateEditor(event)}
        />
      )}
    </div>
  )
}
