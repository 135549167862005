import axios, { AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import binIcon from '../assets/bin.svg'
import { t } from 'i18next'
import { TemplateType } from '@/types'
import { Link } from 'react-router-dom'
import TemplateEditor, { UpdateRequest } from '../components/template-editor'
import { useState } from 'react'
import { AuthenticatedUser } from '../types/authenticated-user'
import { useRecoilState } from 'recoil'
import { authenticatedUser } from '../state/user-state'

export default function Templates() {
  const queryClient = useQueryClient()
  const [showTemplate, setSaveTemplateWindow] = useState(false)
  const [editedTemplate, setEditedTemplate] = useState<TemplateType | null>()
  const [deleteConfirmation, setDeleteConfirmation] = useState<number | undefined>(undefined)
  const [user] = useRecoilState<AuthenticatedUser>(authenticatedUser)
  const { data: templates, isLoading: templatesLoading } = useQuery<TemplateType[]>(
    ['templates'],
    async () => (await axios.get('/api/templates')).data,
  )
  const updateMutation = useMutation({
    mutationFn: (values: { id: number; name: string; description: string; isPublic: boolean }) => {
      const { id, ...rest } = values
      return axios
        .post<UpdateRequest, AxiosResponse<AuthenticatedUser>>(`/api/update-template/${id}`, rest, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(() => queryClient.invalidateQueries({ queryKey: ['templates'] }))
    },
  })
  const deleteMutation = useMutation({
    mutationFn: (values: { id: number }) => {
      const { id } = values
      return axios
        .delete<UpdateRequest, AxiosResponse<AuthenticatedUser>>(`/api/delete-template/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(() => {
          setDeleteConfirmation(undefined)
          queryClient.invalidateQueries({ queryKey: ['templates'] })
        })
    },
  })
  if (templatesLoading) return <div>{t('selection.loading')}</div>
  const onTemplateSave = (data: Pick<TemplateType, 'name' | 'description'> & { isPublic: boolean }) => {
    if (data) {
      updateMutation.mutate({
        id: Number(editedTemplate?.id),
        name: data.name,
        description: data.description,
        isPublic: Boolean(data.isPublic),
      })
    }
  }
  return (
    <div className="px-4 my-12">
      {showTemplate && (
        <TemplateEditor
          fields={{
            name: editedTemplate?.name,
            description: editedTemplate?.description,
            isPublic: Boolean(editedTemplate?.isPublic),
          }}
          filters={editedTemplate?.template}
          onTemplateCreate={onTemplateSave}
          action="edit"
          onStateChange={(event: boolean) => setSaveTemplateWindow(event)}
        />
      )}
      <h1 className="text-4xl font-bold mb-4">{t('templates.templates')}</h1>
      <div className="flex flex-col gap-2">
        {templates &&
          templates.map((item: TemplateType) => (
            <div
              key={item.id.toString()}
              className="flex flex-row items-center justify-between overflow-hidden p-4 rounded-md bg-light-grey w-full h-24"
            >
              <p className="w-1/4 text-md overflow-auto max-h-full">{item.name}</p>
              <p className="w-1/3 text-sm overflow-auto max-h-full">{item.description}</p>
              <div className="flex flex-row gap-2 w-64">
                <Link to={`/templates/${item.id}`} className="py-0.5 px-2 text-sm rounded-lg bg-white border-[1px] border-primary">
                  {t('templates.use')}
                </Link>
                {user.azureId === item.userId && (
                  <>
                    <button
                      onClick={() => {
                        setEditedTemplate(item)
                        setSaveTemplateWindow(true)
                      }}
                      className="py-0.5 px-2 text-sm rounded-lg bg-white border-[1px] border-dark-grey"
                    >
                      {t('templates.edit')}
                    </button>
                    <img className="cursor-pointer" onClick={() => setDeleteConfirmation(Number(item.id))} src={binIcon} />
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
      {deleteConfirmation && (
        <>
          <div className="fixed z-30 top-0 left-0 z-30 w-full h-screen bg-black opacity-30"></div>
          <div className="flex justify-center items-center fixed top-0 left-0 z-40 w-full h-screen">
            <div className="flex flex-col justify-between z-50 bg-white h-fit py-6 px-10 rounded-md h-1/4">
              <h2 className="font-bold text-lg">Delete template?</h2>
              <p className="text-dark-grey text-sm">
                Are you sure you want to delete the template? <br /> This action cannot be undone.
              </p>
              <div className="w-full flex justify-end gap-2">
                <button className="w-24 border-1 border-dark-grey rounded-md py-2" onClick={() => deleteMutation.mutate({ id: deleteConfirmation })}>
                  Delete
                </button>
                <button className="w-24 bg-primary rounded-md py-2 text-white" onClick={() => setDeleteConfirmation(undefined)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
