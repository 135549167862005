import * as z from 'zod'

const dropDownSchema = z.object({
  fieldName: z.enum(['education', 'school', 'province', 'educationLevel', 'study', 'specialisation', 'herkomstCode', 'province', 'city']),
  operator: z.enum(['includes', 'excludes']),
  value: z.array(z.string()),
})

const graduationDatesRangeSchema = z.object({
  fieldName: z.enum(['graduationFrom', 'graduationTo', 'graduationDate', 'dateOfBirth', 'entryDateFrom', 'entryDateTo', 'entryDate']),
  operator: z.enum(['before', 'after']),
  value: z.string(),
})

const validSchema = z
  .object({
    fieldName: z.enum(['validEmail', 'filterEmail', 'validPhone', 'dateOfBirth', 'graduationDate', 'firstName', 'lastName', 'email']),
    operator: z.enum(['is', 'is_not']),
    value: z.string(),
  })
  .optional()

export const fieldsEnum = z.enum([
  'aboneenummer',
  'address',
  'andereMarketsmth',
  'birthDate',
  'city',
  'email',
  'entryDate',
  'firstName',
  'gender',
  'graduationDate',
  'id',
  'lastName',
  'memoryMagazine',
  'middle',
  'modifiedDate',
  'nobiliary',
  'phoneConsultant',
  'phoneMobile',
  'phoneWork',
  'photoNumber',
  'postcode',
  'postcodeSearch',
  'provinceId',
  'universityName',
  'educationName',
  'educationLevelName',
  'studyName',
  'specialisationName',
  'herkomstCodeName',
  'provinceName',
])
export const fieldsSchema = z.array(fieldsEnum)

const templateSchemaWithString = z
  .object({
    fieldName: z.enum(['template']),
    operator: z.enum(['includes', 'excludes']),
    value: z.array(z.string()),
  })
  .optional()

const templateSchema = z
  .object({
    fieldName: z.enum(['template']),
    operator: z.enum(['includes', 'excludes']),
    value: z.object({
      fields: fieldsSchema,
      rules: z.array(z.union([dropDownSchema, graduationDatesRangeSchema, validSchema])),
    }),
  })
  .optional()

export const ruleSchema = z.union([dropDownSchema, graduationDatesRangeSchema, validSchema, templateSchemaWithString])
export const ruleSchemaWithTemplate = z.union([dropDownSchema, graduationDatesRangeSchema, validSchema, templateSchema])

export const searchSchema = z.object({
  fields: fieldsSchema,
  rules: z.array(ruleSchema).optional(),
})
export const searchSchemaWithTemplate = z.object({
  fields: fieldsSchema,
  rules: z.array(ruleSchemaWithTemplate).optional(),
})

export type SearchSchema = z.infer<typeof searchSchema>
export type SearchSchemaWithTemplate = z.infer<typeof searchSchemaWithTemplate>

export type RuleSchema = z.infer<typeof ruleSchema>
export type RuleSchemaWithTemplate = z.infer<typeof ruleSchemaWithTemplate>
export type TemplateSchema = z.infer<typeof templateSchema>
