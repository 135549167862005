import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'virtual:windi.css'
import './index.css'
import './i18n'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: 'https://62df2f8d37294f92856add56fe1cf543@o1364239.ingest.sentry.io/4504588087459840',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: `sofia-search@${import.meta.env.VITE_PACKAGE_VERSION}`,
  })
}

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser'
import { loginRequest, msalConfig } from './auth-config'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RecoilRoot } from 'recoil'

export const msalInstance = new PublicClientApplication(msalConfig)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

const queryClient = new QueryClient()

const SeamlessLogin = () => {
  msalInstance.loginRedirect(loginRequest)
  return null
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <AuthenticatedTemplate>
            <App pca={msalInstance} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SeamlessLogin />
          </UnauthenticatedTemplate>
        </QueryClientProvider>
      </RecoilRoot>
    </MsalProvider>
  </React.StrictMode>,
)
