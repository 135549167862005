import { useQuery } from 'react-query'
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'
import './App.css'
import Header from './components/header'
import Index from './pages'
import Admin from './pages/admin'
import { InteractionStatus, IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useRecoilState } from 'recoil'
import { AuthenticatedUser } from './types/authenticated-user'
import i18n from './i18n'
import { loginRequest } from './auth-config'
import { authenticatedUser } from './state/user-state'
import Disabled from './pages/disabled'
import Templates from './pages/templates'
import TemplateEdit from './pages/template-edit'
import Subscriptions from './pages/subscription'
import CronExecutionDetailsPage from './pages/cron-execution-details'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log('401 reload')
      console.log(error)
      document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
      window.location.href = '/'
      setTimeout(() => window.location.reload(), 1000)
    }
    throw new Error(error)
  },
)

function App({ pca }: { pca: IPublicClientApplication }) {
  const { instance, accounts, inProgress } = useMsal()
  const [user, setUserState] = useRecoilState<AuthenticatedUser>(authenticatedUser)

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      // Retrieve an access token
      instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response) => {
          if (response.idToken) axios.defaults.headers.common['Authorization'] = `Bearer ${response.idToken}`
        })
        .catch((error) => console.log('token error', error))
    }
  }, [inProgress, accounts, instance])

  const fetchUser = useQuery('fetchCurrentUser', (): Promise<AxiosResponse<AuthenticatedUser>> => axios.get('/api/login'), {
    enabled: user !== null,
    refetchOnWindowFocus: false,
  })

  const fetchedUser = fetchUser.data?.data
  useEffect(() => {
    fetchedUser && setUserState(fetchedUser)
  }, [fetchedUser?.id])

  useEffect(() => {
    user && i18n.changeLanguage(user.locale || 'nl')
  }, [user?.locale])

  if (!user?.userType)
    return (
      <>
        Loading <button onClick={() => pca.logout()}>Logout</button>
      </>
    )

  return (
    <Router>
      <Header pca={pca} />
      <Routes>
        <Route path="/admin" element={user.userType === 'admin' ? <Admin /> : <Navigate replace to="/" />} />
        <Route path="/" element={user.userType !== 'disabled' && <Index />} />
        <Route path="/templates" element={user.userType !== 'disabled' && <Templates />} />
        <Route path="/subscriptions" element={user.userType !== 'disabled' && <Subscriptions />} />
        <Route path={'templates/:templateId'} element={user.userType !== 'disabled' && <TemplateEdit />} />
        <Route index path="*" element={user.userType === 'disabled' && <Disabled />} />
        <Route path="/cron-execution-details" element={user.userType !== 'disabled' && <CronExecutionDetailsPage />} />
      </Routes>
    </Router>
  )
}

export default App
