import ReactDOM from 'react-dom'
import styles from './templateEditor.module.css'

type SliderProps = {
  content: JSX.Element
}

export default function Slider({ content }: SliderProps) {
  return ReactDOM.createPortal(
    <div>
      <div className="fixed top-0 left-0 z-30 w-full h-full bg-black opacity-30"></div>
      <div className="flex justify-end fixed top-0 left-0 z-40 w-full h-full">
        <div className={`${styles.slideInRight} flex flex-col justify-between gap-4 w-[460px] h-full z-50 bg-white`}>{content}</div>
      </div>
    </div>,
    document.getElementById('portal') as HTMLElement,
  )
}
