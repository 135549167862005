import { atom } from 'recoil'
import { defaultColumns } from '../components/forms/custom-inputs/filters'

export type Fields =
  | 'aboneenummer'
  | 'address'
  | 'andereMarketsmth'
  | 'birthDate'
  | 'city'
  | 'email'
  | 'entryDate'
  | 'firstName'
  | 'gender'
  | 'graduationDate'
  | 'id'
  | 'lastName'
  | 'memoryMagazine'
  | 'middle'
  | 'modifiedDate'
  | 'nobiliary'
  | 'phoneConsultant'
  | 'phoneMobile'
  | 'phoneWork'
  | 'photoNumber'
  | 'postcode'
  | 'postcodeSearch'
  | 'provinceId'
  | 'universityName'
  | 'educationName'
  | 'educationLevelName'
  | 'studyName'
  | 'specialisationName'
  | 'herkomstCodeName'
  | 'provinceName'

export const columnsState = atom<Fields[]>({
  key: 'columnsState',
  default: defaultColumns,
})
