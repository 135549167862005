import { zodResolver } from '@hookform/resolvers/zod'
import { LayoutProps } from '@sourcelabbg/form/lib'
import axios from 'axios'
import { t } from 'i18next'
import { useQuery } from 'react-query'
import { FormSchema, formSchema } from '../client-schema'
import FilterSection from './filter-section'
import { SofiaAdvancedSearch, SofiaRadioInput, SofiaSearchType } from './forms/custom-inputs/custom-types'
import SofiaForm from './forms/sofia-form'
import styles from '../components/forms/custom-inputs/filters.module.css'
import { RuleSchema, SearchSchema } from '@/schema'
import { BaseSyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import chevronDown from '../assets/chevron-down.svg'
import { useRecoilState } from 'recoil'
import { showSelection } from '../state/show-selection-state'
import { resetFields } from '../state/reset-fields-state'
import { FormProvider, useForm } from 'react-hook-form'
import { PartialFormSchemaWithFields } from '../pages'
import { SofiaOption } from './forms/custom-inputs/sofia-select'

const fieldsToCheck = [
  'advancedSearch',
  'educationIds',
  'educationLevelIds',
  'graduation',
  'entryDate',
  'herkomstCodesIds',
  'studiesIds',
  'validEmail',
  'filterEmail',
  'validPhone',
] as const
export type FieldToCheck = (typeof fieldsToCheck)[number]
export type Filters = Pick<FormSchema, FieldToCheck>

export default function SearchForm(props: {
  formValues?: PartialFormSchemaWithFields
  onSubmit: (searchData: SearchSchema, partialFormDataWithFields: PartialFormSchemaWithFields, event?: BaseSyntheticEvent) => void
  onTemplateSave?: (searchCriteria: SearchSchema) => void
  onSaveAsNewTemplate?: (searchCriteria: SearchSchema) => void
  templateName?: string
  onResetFilter?: (filterKey: keyof FormSchema) => void
}) {
  const saveTemplateRef = useRef<HTMLDivElement>(null)
  const { templateId } = useParams()
  const { formValues, onSubmit, onTemplateSave, onSaveAsNewTemplate, templateName } = props
  const [shouldSearch, setShouldSearch] = useRecoilState<boolean>(showSelection)
  const [fieldState, resetFieldState] = useRecoilState<boolean>(resetFields)
  const [partialFormData, setPartialFormData] = useState<PartialFormSchemaWithFields>(props.formValues || ({} as FormSchema))
  const [isDropdownOpen, setDropdownState] = useState(false)
  const { data: studies, isLoading: studiesLoading } = useQuery(['studies'], async () => (await axios.get(`/api/studies`)).data)
  const { data: educations, isLoading: educationsLoading } = useQuery(['educations'], async () => (await axios.get(`/api/educations`)).data)
  const [isAdvancedSearchEnabled, setAdvancedSearchState] = useState(true)
  const [filterEmail, setFilterEmail] = useState<string | null>(null)

  const formMethods = useForm<any>({
    // TODO: what about FormSchema rather than any?
    defaultValues: props.formValues,
    mode: 'onChange',
  })
  const { data: educationLevels, isLoading: educationLevelsLoading } = useQuery(
    ['education-levels'],
    async () => (await axios.get(`/api/education-levels`)).data,
  )
  const { data: herkomstCodesFromDB, isLoading: herkomstCodesLoading } = useQuery(
    ['herkomst-codes'],
    async () => (await axios.get(`/api/herkomst-codes`)).data,
  )

  const optionNCB = { id: 'subscribedCareerFair', name: t('Carrièrebeurs') }
  const filterEmailOptions = [{ id: 'subscribedJobnet', name: t('Jobnet') }, optionNCB].map((o) => ({ ...o, data: {} }))

  const herkomstCodes = useMemo(() => {
    if (!filterEmail) return herkomstCodesFromDB
    return filterEmail === optionNCB.id
      ? herkomstCodesFromDB.filter((o: SofiaOption) => o.name.toLowerCase().includes('ncb'))
      : herkomstCodesFromDB.filter((o: SofiaOption) => !o.name.toLowerCase().includes('ncb'))
  }, [herkomstCodesFromDB, filterEmail])

  useEffect(() => {
    if (fieldState) {
      fieldsToCheck.forEach((item) => formMethods.setValue(item, undefined))
      resetFieldState(false)
    }
    if (templateId) setShouldSearch(true)
  }, [fieldState])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (event.target && saveTemplateRef.current && !(event.target instanceof Node && saveTemplateRef.current.contains(event.target))) {
        setDropdownState(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [saveTemplateRef])

  function handleFiltersClear(currentFilters: Partial<Filters>) {
    if (
      Object.keys(currentFilters).length === 0 ||
      fieldsToCheck.every((field) => {
        return currentFilters[field] === undefined
      })
    )
      setShouldSearch(false)
  }

  const onFilterReset = (filterKey: keyof FormSchema) => {
    if (filterKey === 'filterEmail') setFilterEmail(null)
    props.onResetFilter?.(filterKey)
  }

  const convertToSchema = useCallback(
    (data: FormSchema): SearchSchema => {
      const formattedData: SearchSchema = {
        fields: data.fields,
        rules: [],
      }
      if (data.educationIds) {
        formattedData.rules?.push({ fieldName: 'education', operator: data.educationIdsOperator || 'includes', value: data.educationIds })
      }
      if (data.educationLevelIds) {
        formattedData.rules?.push({
          fieldName: 'educationLevel',
          operator: data.educationLevelIdsOperator || 'includes',
          value: data.educationLevelIds,
        })
      }

      const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000

      if (data.graduation?.from) {
        formattedData.rules?.push({
          fieldName: 'graduationFrom',
          operator: 'after',
          value: new Date(new Date(data.graduation.from).getTime() - timezoneOffset).toISOString(),
        })
      }
      if (data.graduation?.to) {
        formattedData.rules?.push({
          fieldName: 'graduationTo',
          operator: 'before',
          value: new Date(new Date(data.graduation.to).getTime() - timezoneOffset).toISOString(),
        })
      }
      if (data.entryDate?.from) {
        formattedData.rules?.push({
          fieldName: 'entryDateFrom',
          operator: 'after',
          value: new Date(new Date(data.entryDate.from).getTime() - timezoneOffset).toISOString(),
        })
      }
      if (data.entryDate?.to) {
        formattedData.rules?.push({
          fieldName: 'entryDateTo',
          operator: 'before',
          value: new Date(new Date(data.entryDate.to).getTime() - timezoneOffset).toISOString(),
        })
      }

      formattedData.rules?.push({
        fieldName: 'herkomstCode',
        operator: data.herkomstCodesIdsOperator || 'includes',
        value: data.herkomstCodesIds?.length ? data.herkomstCodesIds : herkomstCodes?.map((o: SofiaOption) => o.id),
      })

      if (data.studiesIds) {
        formattedData.rules?.push({ fieldName: 'study', operator: data.studiesIdsOperator || 'includes', value: data.studiesIds })
      }
      if (data.validEmail) {
        formattedData.rules?.push({ fieldName: 'validEmail', operator: 'is', value: data.validEmail })
        formattedData.rules?.push({ fieldName: 'filterEmail', operator: 'is', value: data.filterEmail })
      }
      if (data.validPhone) {
        formattedData.rules?.push({ fieldName: 'validPhone', operator: 'is', value: data.validPhone })
      }
      data.advancedSearch?.map((filter: RuleSchema) => formattedData.rules?.push(filter))
      return formattedData
    },
    [herkomstCodes],
  )

  const searchCriteria = useMemo(
    () =>
      convertToSchema({
        ...partialFormData,
        filterEmail: partialFormData.filterEmail ?? '',
        advancedSearch: partialFormData.advancedSearch?.filter((row) => row?.fieldName && row?.operator && row.value?.[0]),
        fields: formValues?.fields ?? [],
      }),
    [partialFormData, formValues],
  )

  const onShowSelection = () => onSubmit(searchCriteria, partialFormData)

  const fields: SofiaSearchType[] = [
    {
      render: (layoutProps: LayoutProps) => {
        return (
          <>
            <div className="flex flex-col gap-4 h-[120px] w-full px-4">
              <div className="flex flex-row h-2/5 justify-between w-full">
                <h1 className="text-4xl font-bold whitespace-nowrap overflow-hidden text-ellipsis max-w-10/12">
                  {templateId ? templateName : t('selection.selection_title')}
                </h1>
                <div className="flex flex-row gap-2">
                  <button
                    className={`h-7 px-2 text-sm rounded-md bg-primary text-white ${shouldSearch ? '' : 'disabled bg-dark-grey cursor-default'}`}
                    type="button"
                    onClick={onShowSelection}
                    disabled={!shouldSearch}
                  >
                    {t('selection.show_selection')}
                  </button>
                  {!templateId && (
                    <button type="submit" name="action" value="editTemplate" className="h-7 px-2 text-sm rounded-md border-[1px] border-primary">
                      {t('selection.save_template')}
                    </button>
                  )}
                  {templateId && (
                    <div>
                      <button
                        id="dropdown-toggler"
                        className="flex flex-row items-center justify-evenly h-7 w-20 bg-primary text-white text-sm rounded-md"
                        type="button"
                        onClick={() => setDropdownState(!isDropdownOpen)}
                      >
                        {t('templates.save')}
                        <img id="dropdown-toggler" className={`w-auto`} src={chevronDown} alt="" />
                      </button>
                      <div
                        ref={saveTemplateRef}
                        className={`mt-1 absolute z-50 flex flex-col right-4 ${!isDropdownOpen && 'hidden'}`}
                        onClick={() => setDropdownState(!isDropdownOpen)}
                      >
                        <button
                          className="hover:bg-light-grey h-7 w-full px-3 text-left text-sm border-[1px] border-table-outline rounded-t-md bg-white"
                          type="button"
                          onClick={() => onTemplateSave?.(searchCriteria)}
                        >
                          {t('templates.save_changes')}
                        </button>
                        <button
                          className="hover:bg-light-grey h-7 w-full px-3 text-left text-sm border-[1px] border-t-0 border-table-outline rounded-b-md  bg-white"
                          type="button"
                          onClick={() => onSaveAsNewTemplate?.(searchCriteria)}
                        >
                          {t('templates.save_as_new')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <FilterSection {...layoutProps} onFiltersClear={handleFiltersClear} onFilterReset={onFilterReset} />
            </div>
            <div className="flex flex-col px-4">
              <h2 className="text-lg">{t('selection.simple_search')}</h2>
              <hr className="h-[1px] my-4 bg-dark-grey" />
            </div>
          </>
        )
      },
    },

    {
      className: 'px-8 grid grid-cols-3 gap-4',
      children: [
        {
          name: 'filterEmail',
          type: 'custom',
          customControlType: 'sofia-radio-input',
          options: filterEmailOptions,
          uiOptions: {
            label: 'selection.filter_email_title',
          },
        } as SofiaRadioInput,

        {
          name: 'graduation',
          type: 'custom',
          customControlType: 'sofia-month-input',
          uiOptions: {
            label: 'selection.graduation_date_title',
          },
        },

        {
          name: 'entryDate',
          type: 'custom',
          customControlType: 'sofia-month-input',
          uiOptions: {
            label: 'selection.entry_date_title',
          },
        },

        {
          name: 'educationIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: educations,
          uiOptions: {
            label: 'selection.education_title',
          },
        },

        {
          name: 'studiesIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: studies,
          uiOptions: {
            label: 'selection.study_profile_title',
          },
        },

        {
          name: 'educationLevelIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: educationLevels,
          uiOptions: {
            label: 'selection.education_level_title',
          },
        },

        {
          name: 'herkomstCodesIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: herkomstCodes,
          uiOptions: {
            label: 'selection.herkomst_codes',
          },
        },

        {
          name: 'validEmail',
          type: 'custom',
          customControlType: 'sofia-radio-input',
          uiOptions: {
            label: 'selection.valid_email_title',
          },
        },

        {
          name: 'validPhone',
          type: 'custom',
          customControlType: 'sofia-radio-input',
          uiOptions: {
            label: 'selection.valid_phone_title',
          },
        },
        { render: () => <div>&nbsp;</div> },
      ],
    },
    {
      render: (layoutProps: LayoutProps) => (
        <div className="flex flex-row gap-4 px-8">
          <input
            className={`${styles.advancedToggle} bg-gray-200 checked:bg-primary relative inline-flex flex-shrink-0 w-11 h-6 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out`}
            id="advanced-seatch-toggle"
            type="checkbox"
            onChange={() => {
              setAdvancedSearchState(!isAdvancedSearchEnabled)
              layoutProps.setValue(
                'advanced-search',
                layoutProps.formValues?.advancedSearch?.splice(0, layoutProps.formValues?.advancedSearch?.length),
              )
            }}
            role="switch"
            defaultChecked={isAdvancedSearchEnabled}
          />
          <h2>{t('selection.advanced_search')}</h2>
        </div>
      ),
    },
    {
      name: 'advancedSearch',
      type: 'custom',
      customControlType: 'sofia-advanced-search',
      onShowSelection,
      uiOptions: {
        visible() {
          return isAdvancedSearchEnabled
        },
      },
    } as SofiaAdvancedSearch,
  ]

  const onFieldChange = (name: string, change: { new: string[] | string }, oldFormValues: FormSchema) => {
    if (!oldFormValues) setShouldSearch(false)
    const formValues = { ...oldFormValues, [name]: change.new }
    const { fields: _fields, validEmail: _validEmail, graduation, ...restSearchCriteria } = formValues
    setPartialFormData(formValues)
    const hasGraduation = graduation && !!Object.values(graduation).find((value) => value != undefined)
    const hasValue = !!Object.keys(restSearchCriteria).find((key: string) => {
      if (key.indexOf('Operator') > -1) return false
      const value = restSearchCriteria[key as keyof typeof restSearchCriteria]
      return value != undefined && (Array.isArray(value) ? value.length > 0 : true)
    })

    setShouldSearch( (formValues.validEmail ? !!formValues.filterEmail : true) && !!(hasValue || hasGraduation))

    if (name === 'filterEmail') {
      setFilterEmail(change.new as string)
      formMethods.setValue('herkomstCodesIds', undefined)
    }
  }

  if (studiesLoading || educationLevelsLoading || educationsLoading || herkomstCodesLoading || !formValues) return <div>{t('selection.loading')}</div>

  return (
    <FormProvider {...formMethods}>
      <SofiaForm
        fields={fields}
        values={formValues}
        resolver={zodResolver(formSchema)}
        onSubmit={(data, e?: BaseSyntheticEvent) => {
          onSubmit(
            convertToSchema({
              ...data,
              advancedSearch: data.advancedSearch?.filter((row) => row?.fieldName && row?.operator && row.value?.[0]),
              fields: formValues.fields,
            }),
            partialFormData,
            e,
          )
        }}
        onFieldChange={onFieldChange}
      />
    </FormProvider>
  )
}
