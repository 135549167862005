import { useQuery } from 'react-query'
import axios from 'axios'
import { Unsubscribers } from '../../../server/src/db/generated'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js'
import DatePicker from 'react-datepicker'
import { useState } from 'react'

type OverviewProps = {
  onClose: () => void
}

const sumOfNumbers = (array: number[]) => array.reduce((accumulator, current) => accumulator + current, 0)

const subtractYears = (date: Date, years: number) => {
  date.setFullYear(date.getFullYear() - years)

  return date
}

export default function Overview({ onClose }: OverviewProps) {
  const [startDate, setStartDate] = useState<Date | null>(subtractYears(new Date(), 1))
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [isSubscribedClicked, setIsSubscribedClicked] = useState<boolean>(true)
  const { data, isLoading } = useQuery<Unsubscribers[]>(
    ['subscriptions/overview', startDate, endDate],
    async () => (await axios.get(`/api/subscriptions/overview/${startDate}/${endDate}`)).data,
  )

  const filterData = (company: 'jobnet' | 'national_career_fair') => {
    const eventTypeFilter = isSubscribedClicked ? 'sign-up' : 'unsubscribe'

    const filteredData = data?.filter(
      (subscription: Unsubscribers) => subscription.eventType === eventTypeFilter && subscription.emailList === company,
    )

    return filteredData
  }

  const nationalCareerFairNumbers = filterData('national_career_fair')?.length
  const jobnetNumbers = filterData('jobnet')?.length

  const DoughnutChart = () => {
    ChartJS.register(ArcElement, Legend, Tooltip)

    const optionsDoughnutChart = {
      responsive: true,
      aspectRatio: 2,
      plugins: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: true,
          position: 'bottom' as const,
          padding: 20,
          labels: {
            pointStyleWidth: 32,
            usePointStyle: true,
            pointStyle: 'rectRounded',
          },
        },
        radius: 200,
        title: {
          display: false,
        },
      },
    }

    const data = {
      labels: ['DeCarriereBeurs', 'Jobnet'],
      datasets: [
        {
          label: '',
          data: [nationalCareerFairNumbers, jobnetNumbers],
          backgroundColor: ['rgba(99, 171, 253, 1)', 'rgba(22, 91, 170, 1)'],
          borderColor: ['rgba(99, 171, 253, 1)', 'rgba(22, 91, 170, 1)'],
          borderWidth: 2,
          cutout: '70%',
        },
      ],
    }

    const doughnutLabel = {
      id: 'doughnutLabel',
      beforeDatasetDraw(chart: any) {
        const { ctx, data } = chart

        ctx.save()
        const xCoor = chart.getDatasetMeta(0).data[0].x
        const yCoor = chart.getDatasetMeta(0).data[0].y

        ctx.font = 'bold 14px sans-serif'
        ctx.fillStyle = 'rgba(54, 162, 235, 1)'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        const text = `Total: ${sumOfNumbers(data.datasets[0].data)}`
        ctx.fillText(text, xCoor, yCoor)
      },
    }

    return <Doughnut data={data} options={optionsDoughnutChart} plugins={[doughnutLabel]} />
  }

  if (isLoading) return <div>Loading</div>

  return (
    <div className="p-6">
      <div className="flex flex-row justify-between items-start">
        <div>
          <p className="text-overview-dark-grey text-lg">Overview</p>
          <p className="text-dark-grey text-sm">Visual overview of all the subscriptions</p>
        </div>
        <button className="p-1" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="w-full h-px bg-overview-light-grey mt-1"></div>
      <div className="flex flex-row pt-6 pb-12 space-x-2">
        <div>
          <p className="pb-1 text-xs text-overview-dark-grey">Start Date</p>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="border-radio-outline rounded-md text-sm text-dark-grey"
            showYearDropdown
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            minDate={new Date(2000, 0, 1, 0, 0, 0, 0)}
            maxDate={new Date()}
          />
        </div>
        <div>
          <p className="pb-1 text-xs text-overview-dark-grey">End Date</p>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="border-radio-outline rounded-md text-sm text-dark-grey"
            showYearDropdown
            showFullMonthYearPicker
            scrollableYearDropdown
          />
        </div>
      </div>
      <div className="pb-12 m-auto">
        <DoughnutChart />
      </div>
      <div className="flex flex-row gap-2">
        <button
          className={`font-bold text-xs rounded py-2 m-auto w-full ${
            isSubscribedClicked ? 'bg-overview-blue text-white' : 'bg-overview-white text-dark-grey'
          }`}
          onClick={() => setIsSubscribedClicked(true)}
        >
          Subscibed
        </button>
        <button
          className={`font-bold text-xs rounded py-2 m-auto w-full ${
            isSubscribedClicked ? 'bg-overview-white text-dark-grey' : 'bg-overview-blue text-white'
          }`}
          onClick={() => setIsSubscribedClicked(false)}
        >
          Unsubscribed
        </button>
      </div>
    </div>
  )
}
