import { Configuration, PopupRequest } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID as string,
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI as string,
    knownAuthorities: [import.meta.env.VITE_MSAL_KNOWN_AUTHORITIES] as string[],
    // redirectUri: 'http://localhost:3000/auth/success',
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['openid', 'User.Read'],
  authority: import.meta.env.VITE_MSAL_LOGIN_AUTHORITY as string,
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
}
