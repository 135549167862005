import { CustomControl, Field, Layout } from '@sourcelabbg/form/lib'
import { SofiaOption } from './sofia-select'

export interface SofiaRadioInput extends CustomControl {
  customControlType: 'sofia-radio-input'
  componentOptions?: {
    debounce?: number
    autoComplete?: boolean
    size?: number
  }
  value?: string
  options?: SofiaOption[]
}

export interface SofiaSelect extends CustomControl {
  customControlType: 'sofia-select'
  value?: string[]
  isMulti?: boolean
  inclusionIcon?: boolean
  options: SofiaOption[]
  minInputLengthForSelectAll?: number
}

export interface SofiaMonthInput extends CustomControl {
  customControlType: 'sofia-month-input'
  value?: string
}

export interface SofiaFilters extends CustomControl {
  customControlType: 'sofia-filters'
  value?: string[]
}

export interface SofiaAdvancedSearch extends CustomControl {
  customControlType: 'sofia-advanced-search'
  value?: string[]
  onShowSelection?: () => void
}

export type SofiaSearchType = Field | SofiaSelect | SofiaMonthInput | SofiaFilters | Layout<SofiaSearchType>

export const isNotSofiaSearchField = (obj: any): obj is Layout<SofiaSearchType> => obj?.type === undefined
