import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '@/i18n/en/common.json'
import nl from '@/i18n/nl/common.json'

i18n.use(initReactI18next).init({
  lng: 'nl',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources: {
    en: { translation: en },
    nl: { translation: nl },
  },
})

export default i18n
