import { useQuery } from 'react-query'
import axios from 'axios'
import { EmailLastStatus } from '../../../server/src/db/generated'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import UserSubscriptionsHistory from '../components/user-subscriptions-history'
import chevronDown from '../assets/chevron-down.svg'
import { MagnifyingGlassIcon, BarsArrowUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { useDebouncedCallback } from 'use-debounce'
import Overview from '../components/overview'
import Slider from '../components/slider'
import { Pagination } from '../components/pagination'

const subscriptionColumns = ['name', 'email', 'jobnet_list_status', 'career_fair_list_status', 'zerobounce_status']

export default function Subscriptions() {
  const { t } = useTranslation()
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false)
  const [carrierebeurs, setCarrierebeurs] = useState<boolean>(false)
  const [jobnet, setJobnet] = useState<boolean>(false)
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false)
  const [sort, setSort] = useState<'asc' | 'desc'>()
  const [search, setSearch] = useState<string>('')
  const [isDropdownOpen, setDropdownState] = useState(false)
  const [isSortDropdownOpen, setSortDropdownState] = useState(false)

  const [activeStep, setActiveStep] = useState(1)
  const selectActiveStep = (step: number) => {
    setActiveStep(step)
  }

  const debounced = useDebouncedCallback((value) => {
    setSearch(value)
  }, 700)

  const filters = { subscribed, unsubscribed, carrierebeurs, jobnet, invalidEmail, sort, search, activeStep }

  const fetchSubscriptions = async () => {
    const { data } = await axios.get('/api/subscriptions', {
      params: {
        filter: filters,
      },
    })

    return data
  }

  const [showUserSubscriptionsHistory, setShowUserSubscriptionsHistory] = useState(false)
  const [email, setEmail] = useState<string>()
  const [showOverview, setShowOverview] = useState(false)

  const { data: subscriptions, isLoading: subscriptionsLoading } = useQuery<{
    data: EmailLastStatus[]
    pageCount: number
    pageNumber: number
  }>(['subscriptions', filters], fetchSubscriptions)

  return (
    <div className="w-full my-12">
      {subscriptionsLoading && !subscriptions ? (
        <>
          <div className="flex flex-row text-4xl font-bold px-8">
            <h1>{t('subscriptions.subscriptions')}</h1>
          </div>
          <div className="h-1000px mt-14 px-8">
            <div id="preloader">
              <div id="loader" className="table"></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row justify-between items-center mt-14 px-8">
            <div className="flex flex-row text-4xl font-bold">
              <h1>{t('subscriptions.subscriptions')}</h1>
            </div>
            <div className="flex flex-row gap-2">
              <div>
                <button
                  id="dropdown-toggler"
                  className="flex flex-row items-center justify-evenly h-7 w-28 bg-primary text-white text-xs rounded-md"
                  onClick={() => setDropdownState(!isDropdownOpen)}
                >
                  {t('selection.download')}
                  <img id="dropdown-toggler" className={`w-auto`} src={chevronDown} alt="" />
                </button>
                <div className={`mt-1 absolute z-50 ${!isDropdownOpen && 'hidden'}`}>
                  <form action="/api/export" method="post" className="flex flex-col w-28">
                    <button
                      className="hover:bg-light-grey h-7 w-28 pl-3 text-left text-sm border-[1px] border-table-outline rounded-t-md bg-white"
                      type="submit"
                      name="fileType"
                      value="csv"
                    >
                      CSV
                    </button>
                    <button
                      className="hover:bg-light-grey h-7 w-28 pl-3 text-left text-sm border-[1px] border-t-0 border-table-outline rounded-b-md  bg-white"
                      type="submit"
                      name="fileType"
                      value="xlsx"
                    >
                      Excel
                    </button>
                  </form>
                </div>
              </div>
              <button
                onClick={() => {
                  setShowOverview(true)
                }}
                type="button"
                className="h-7 border-2 px-2 border-primary rounded-md text-xs"
              >
                {t('subscriptions.overview')}
              </button>
            </div>
          </div>
          <div className="flex px-8 pt-8 text-dark-grey">
            <div className="relative w-2/8 ">
              <div className="absolute inset-y-0 start-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="w-5 h-5" />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full h-10 pl-10 text-sm text-gray-900 border border-gray-300 rounded-l-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder={t('subscriptions.search') || 'Zoek kandidaten'}
                onChange={(e) => debounced(e.target.value)}
                defaultValue={search}
              />
            </div>
            <div className="flex flex-row">
              <div>
                <button
                  id="dropdown-toggler"
                  className="h-10 px-1 items-center flex w-40 items-center flex justify-between text-sm border border-r-gray-300 border-t-gray-300 border-b-gray-300 border-l-light-grey bg-light-grey rounded-r-lg hover:bg-radio-outline focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-radio-outline"
                  onClick={() => setSortDropdownState(!isSortDropdownOpen)}
                >
                  <BarsArrowUpIcon className="w-5 h-5" />

                  <span className="text-sm">{t(`subscriptions.${sort || 'asc'}`)}</span>
                  <ChevronDownIcon className="w-5 h-5" />
                </button>
                <div className={`w-40 grid absolute z-50 ${!isSortDropdownOpen && 'hidden'}`}>
                  <button
                    className="hover:bg-light-grey py-2 pl-3 text-left text-sm border-[1px] border-table-outline rounded-tr-md border-b-0 bg-white"
                    type="button"
                    onClick={() => {
                      setSort('asc')
                      setSortDropdownState(false)
                    }}
                  >
                    {t('subscriptions.asc')}
                  </button>
                  <button
                    className="hover:bg-light-grey py-2  pl-3 text-left text-sm border-[1px] border-t-0 border-table-outline bg-white"
                    type="button"
                    onClick={() => {
                      setSort('desc')
                      setSortDropdownState(false)
                    }}
                  >
                    {t('subscriptions.desc')}
                  </button>
                  <button
                    className="hover:bg-light-grey py-2  pl-3 text-left text-sm border-[1px] border-t-0 border-table-outline rounded-b-md bg-white"
                    onClick={() => {
                      setSortDropdownState(false)
                    }}
                  >
                    {t('subscriptions.most_recent')}
                  </button>
                </div>
              </div>
            </div>
            <div className="ml-2 flex justify-between w-full">
              <div className="flex flex gap-2">
                <button
                  type="button"
                  className={`${subscribed ? 'activeSubscribersFilters' : undefined} subscribersFilters`}
                  onClick={() => setSubscribed(!subscribed)}
                >
                  {t('subscriptions.subscribed')}
                </button>
                <button
                  type="button"
                  className={`${unsubscribed ? 'activeSubscribersFilters' : undefined} subscribersFilters`}
                  onClick={() => setUnsubscribed(!unsubscribed)}
                >
                  {t('subscriptions.unsubscribed')}
                </button>
                <button
                  type="button"
                  className={`${carrierebeurs ? 'activeSubscribersFilters' : undefined} subscribersFilters`}
                  onClick={() => setCarrierebeurs(!carrierebeurs)}
                >
                  Carrièrebeurs
                </button>
                <button
                  type="button"
                  className={`${invalidEmail ? 'activeSubscribersFilters' : undefined} subscribersFilters`}
                  onClick={() => setInvalidEmail(!invalidEmail)}
                >
                  Invalid Emails
                </button>
                <button
                  type="button"
                  className={`${jobnet ? 'activeSubscribersFilters' : undefined} subscribersFilters`}
                  onClick={() => setJobnet(!jobnet)}
                >
                  Jobnet
                </button>
              </div>
              <button
                type="button"
                className="clearAll"
                onClick={() => {
                  setSubscribed(false)
                  setUnsubscribed(false)
                  setCarrierebeurs(false)
                  setJobnet(false)
                  setInvalidEmail(false)
                }}
              >
                {t('subscriptions.clear_all')} <span className="pl-4">&#x2715;</span>
              </button>
            </div>
          </div>
          <div className="px-8 my-4 overflow-scroll overflow-scroll">
            <table className="bordered min-w-full rounded-full divide-y divide-table-outline">
              <thead className="bg-light-grey">
                <tr>
                  {subscriptionColumns?.map((column, index) => (
                    <th key={index} scope="col" className="py-3.5 text-left text-base font-normal text-dark-grey sm:pl-6 lg:pl-8">
                      {t(`subscriptions.${column}`)}
                    </th>
                  ))}
                  <th scope="col" className="py-3.5 text-left text-base font-normal text-dark-grey sm:pl-6 lg:pl-8"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-table-outline bg-white">
                {subscriptions?.data?.map((subscription, i: number) => {
                  return (
                    <tr key={i}>
                      <td className="text-dark-grey whitespace-nowrap py-4 pl-4  text-base sm:pl-6 lg:pl-8">
                        {[subscription.firstName, subscription.middleName, subscription.lastName].join(' ')}
                      </td>
                      <td className="text-dark-grey whitespace-nowrap py-4 pl-4  text-base sm:pl-6 lg:pl-8">{subscription.email}</td>
                      <td className="text-dark-grey whitespace-nowrap py-4 pl-4  text-base sm:pl-6 lg:pl-8">{subscription.jobnetListLastStatus}</td>
                      <td className="text-dark-grey whitespace-nowrap py-4 pl-4  text-base sm:pl-6 lg:pl-8">
                        {subscription.careerFairListLastStatus}
                      </td>
                      <td className="text-dark-grey whitespace-nowrap py-4 pl-4  text-base sm:pl-6 lg:pl-8">
                        {t(`subscriptions.${subscription.zerobounceStatus}`)}
                      </td>
                      <td className="text-dark-grey whitespace-nowrap py-4 pl-4  text-base sm:pl-6 lg:pl-8">
                        <button
                          onClick={() => {
                            setEmail(subscription.email)
                            setShowUserSubscriptionsHistory(true)
                          }}
                        >
                          &#x24D8;
                        </button>
                      </td>
                    </tr>
                  )
                })}
                {showUserSubscriptionsHistory && email && (
                  <UserSubscriptionsHistory email={email} onStateChange={(event: boolean) => setShowUserSubscriptionsHistory(event)} />
                )}
                {showOverview && <Slider content={<Overview onClose={() => setShowOverview(false)} />} />}
              </tbody>
            </table>
            <Pagination
              selectActiveStep={selectActiveStep}
              pageCount={Number(subscriptions?.pageCount)}
              pageNumber={Number(subscriptions?.pageNumber)}
            />
          </div>
        </>
      )}
    </div>
  )
}
