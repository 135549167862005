import { NavLink, useNavigate } from 'react-router-dom'

import styles from './header.module.css'
import sofiaSearchLogo from '../assets/logo.svg'
import avatar from '../assets/avatar.svg'
import logoutIcon from '../assets/logout.svg'
import { useTranslation } from 'react-i18next'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useRecoilValue } from 'recoil'
import { AuthenticatedUser } from '../types/authenticated-user'
import { authenticatedUser } from '../state/user-state'

export default function Header({ pca }: { pca: IPublicClientApplication }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useRecoilValue<AuthenticatedUser | undefined>(authenticatedUser)

  if (!user) return <>Not authenticated</>

  const navLinkClass = ({ isActive }: { isActive: boolean }) => `h-2/3 rounded-md p-2 text-center ${isActive ? styles.active : ''}`

  return (
    <header className="flex flex-row justify-between w-full h-16 border-b-[1px] mb-4 px-4">
      <div className="flex flex-row items-center gap-4">
        <img src={sofiaSearchLogo} alt="" className="w-auto" />
        <NavLink to="/" className={navLinkClass}>
          {t('header.selection')}
        </NavLink>
        <NavLink to="/templates" className={navLinkClass}>
          {t('header.templates')}
        </NavLink>
        <NavLink to="/subscriptions" className={navLinkClass}>
          {t('header.subscriptions')}
        </NavLink>
        {user.userType === 'admin' && (
          <NavLink to="/admin" className={navLinkClass}>
            {t('header.users')}
          </NavLink>
        )}
        <NavLink to="/cron-execution-details" className={navLinkClass}>
          {t('header.cron')}
        </NavLink>
      </div>
      <div className="flex flex-row items-center gap-8">
        <div className="flex flex-row gap-4 items-center">
          <img src={avatar} alt="" className="w-auto " />
          <p>{user?.name}</p>
        </div>
        <a
          onClick={() => {
            navigate('/')
            pca.logout()
          }}
        >
          <img src={logoutIcon} alt="" className="w-auto cursor-pointer" />
        </a>
      </div>
    </header>
  )
}
