import { BaseSyntheticEvent, useState } from 'react'
import Result from '../components/result'
import SearchForm from '../components/search-form'
import TemplateEditor, { UpdateRequest } from '../components/template-editor'
import { SearchSchema } from '../../../server/src/search-schema'
import { useMutation } from 'react-query'
import axios from 'axios'
import { useRecoilState } from 'recoil'
import { AuthenticatedUser } from '../types/authenticated-user'
import { authenticatedUser } from '../state/user-state'
import { Fields, columnsState } from '../state/columns-state'
import { TemplateType } from '../../../server/src/types'
import { FormSchema } from '../client-schema'

export type PartialFormSchemaWithFields = Partial<FormSchema> & Pick<FormSchema, 'fields'>

export default function Index() {
  const [user] = useRecoilState<AuthenticatedUser>(authenticatedUser)
  const [toggles] = useRecoilState<Fields[]>(columnsState)
  const [searchCriteria, setSearchCriteria] = useState<SearchSchema>({} as SearchSchema)
  const [formValues, setFormValues] = useState<PartialFormSchemaWithFields>({ fields: toggles })
  const [showTemplate, setSaveTemplateWindow] = useState(false)
  const createTemplateMutation = useMutation({
    mutationFn: (values: UpdateRequest) => {
      return axios.post('/api/templates', values, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    },
  })
  const onSubmit = (data: SearchSchema, partialFormValues: PartialFormSchemaWithFields, event?: BaseSyntheticEvent) => {
    const action = ((event?.nativeEvent as SubmitEvent) || undefined)?.submitter?.getAttribute('name')
    setFormValues(partialFormValues)
    setSearchCriteria(data)
    if (action === 'action') {
      setSaveTemplateWindow(true)
    }
  }
  const onTemplateSave = (data: Pick<TemplateType, 'name' | 'description' | 'template'> & { isPublic: boolean }) => {
    if (data) {
      createTemplateMutation.mutate({
        userId: user.azureId,
        name: data.name,
        description: data.description,
        isPublic: Boolean(data.isPublic),
        template: data.template,
      })
    }
  }
  return (
    <div className="w-full my-12">
      <SearchForm onSubmit={onSubmit} formValues={formValues} />
      {Object.keys(searchCriteria).length > 0 && (
        <Result
          resetFilters={() => {
            setSearchCriteria({} as SearchSchema)
          }}
          searchCriteria={searchCriteria}
        />
      )}
      {showTemplate && (
        <TemplateEditor filters={searchCriteria} onTemplateCreate={onTemplateSave} onStateChange={(event: boolean) => setSaveTemplateWindow(event)} />
      )}
    </div>
  )
}
